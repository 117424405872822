<!-- src/components/FootModuleLoader.vue -->
<template>
  <component :is="footModuleComponent" v-bind="passThroughProps" />
</template>

<script>
const footModule_1 = () => import('@/pages/modules/foot/footModule_1.vue');
const footModule_2 = () => import('@/pages/modules/foot/footModule_2.vue');

export default {
  components: {
    footModule_1,
    footModule_2,
  },
  props: {
    themeConfig: {
      type: Object,
      required: true,
    },
    deviceType: {
      type: String,
      default: '',
    },
    host: {
      type: String,
      default: '',
    },
  },
  computed: {
    footModuleComponent () {
      const moduleType = this.themeConfig.fooType || 1;
      return `footModule_${moduleType}`;
    },
    passThroughProps () {
      const { ...rest } = this.$props;
      return rest;
    },
  },
};
</script>